/* This example requires Tailwind CSS v2.0+ */
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";


const navigation = {
  main: [
    { name: 'Home', href: '/', id: 'HomeBox' },
    { name: 'About', href: '/about', id: 'aboutBox' },
    { name: 'Albums', href: '/albums', id: 'albumBox' },
    { name: 'Gallery', href: '/gallery', id: 'galleryBox' },
    { name: 'Contact', href: '/contact', id: 'ContactBox' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/rudie.crossman',
      icon: (props) => (
        <motion.svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <motion.path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </motion.svg>


      ),
    },
    {
      name: 'Youtube',
      href: 'https://www.youtube.com/channel/UCFjkS13HEWO0iEGZj0VOjxg',
      icon: (props) => (
        <svg
          viewBox="0 0 1024 1024"
          fill="currentColor"
          {...props}
        >
          <path d="M941.3 296.1a112.3 112.3 0 00-79.2-79.3C792.2 198 512 198 512 198s-280.2 0-350.1 18.7A112.12 112.12 0 0082.7 296C64 366 64 512 64 512s0 146 18.7 215.9c10.3 38.6 40.7 69 79.2 79.3C231.8 826 512 826 512 826s280.2 0 350.1-18.8c38.6-10.3 68.9-40.7 79.2-79.3C960 658 960 512 960 512s0-146-18.7-215.9zM423 646V378l232 133-232 135z" />
        </svg>


      ),
    },
    {
      name: 'Spotify',
      href: 'https://open.spotify.com/artist/3QC8RaxpLGVXMCHKbWAx1w',
      icon: (props) => (
        <svg
          fill="currentColor"
          viewBox="0 0 16 16"
          {...props}
        >
          <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm3.669 11.538a.498.498 0 01-.686.165c-1.879-1.147-4.243-1.407-7.028-.77a.499.499 0 01-.222-.973c3.048-.696 5.662-.397 7.77.892a.5.5 0 01.166.686zm.979-2.178a.624.624 0 01-.858.205c-2.15-1.321-5.428-1.704-7.972-.932a.625.625 0 01-.362-1.194c2.905-.881 6.517-.454 8.986 1.063a.624.624 0 01.206.858zm.084-2.268C10.154 5.56 5.9 5.419 3.438 6.166a.748.748 0 11-.434-1.432c2.825-.857 7.523-.692 10.492 1.07a.747.747 0 11-.764 1.288z" />
        </svg>


      ),
    },
    {
      name: 'Itunes',
      href: 'https://music.apple.com/us/artist/rudie-crossman/1513900469',
      icon: (props) => (
        <svg
          viewBox="0 0 24 24"
          fill="currentColor"
          {...props}
        >
          <path d="M11.977 23.999c-2.483 0-4.898-.777-6.954-2.262a11.928 11.928 0 01-4.814-7.806A11.954 11.954 0 012.3 4.994 11.85 11.85 0 0110.08.159a11.831 11.831 0 018.896 2.104 11.933 11.933 0 014.815 7.807 11.958 11.958 0 01-2.091 8.937 11.855 11.855 0 01-7.78 4.835 12.17 12.17 0 01-1.943.157zm-6.474-2.926a11.022 11.022 0 008.284 1.96 11.044 11.044 0 007.246-4.504c3.583-5.003 2.445-12.003-2.538-15.603a11.022 11.022 0 00-8.284-1.96A11.046 11.046 0 002.966 5.47C-.618 10.474.521 17.473 5.503 21.073zm10.606-3.552a2.08 2.08 0 001.458-1.468l.062-.216.008-5.786c.006-4.334 0-5.814-.024-5.895a.535.535 0 00-.118-.214.514.514 0 00-.276-.073c-.073 0-.325.035-.56.078-1.041.19-7.176 1.411-7.281 1.45a.786.786 0 00-.399.354l-.065.128s-.031 9.07-.078 9.172a.7.7 0 01-.376.35 9.425 9.425 0 01-.609.137c-1.231.245-1.688.421-2.075.801-.22.216-.382.51-.453.82-.067.294-.045.736.051 1.005.1.281.262.521.473.71.192.148.419.258.674.324.563.144 1.618-.016 2.158-.328a2.36 2.36 0 00.667-.629c.06-.089.15-.268.2-.399.176-.456.181-8.581.204-8.683a.44.44 0 01.32-.344c.147-.04 6.055-1.207 6.222-1.23.146-.02.284.027.36.12a.29.29 0 01.109.096c.048.07.051.213.058 2.785.008 2.96.012 2.892-.149 3.079-.117.136-.263.189-.864.31-.914.188-1.226.276-1.576.447-.437.213-.679.446-.867.835a1.58 1.58 0 00-.182.754c.001.49.169.871.55 1.245.035.034.069.066.104.097.192.148.387.238.633.294.37.082 1.124.025 1.641-.126z" />
        </svg>


      ),
    },
    {
      name: 'Soundcloud',
      href: 'https://soundcloud.com/rudiecrossman',
      icon: (props) => (
        <svg
          viewBox="0 0 1000 1000"
          fill="currentColor"
          {...props}
        >
          <path d="M34 672c0 2.667-3.333 4-10 4-4 0-7.333-1.333-10-4L0 602l14-72c2.667-2.667 6-4 10-4 6.667 0 10 1.333 10 4l16 72-16 70m102 38c0 5.333-4 8-12 8s-12-2.667-12-8l-12-108 12-166c0-5.333 4-8 12-8s12 2.667 12 8l14 166-14 108m102-2c0 6.667-4.667 10-14 10s-14-2.667-14-8l-10-108 10-222c0-6.667 4.667-10 14-10s14 3.333 14 10l12 222-12 106m102 0c0 6.667-5.333 10-16 10s-16-3.333-16-10l-8-106 8-224c0-6.667 5.333-10 16-10s16 3.333 16 10l10 224-10 106m102-2c0 8-6 12-18 12-10.667 0-16.667-4-18-12l-6-104 6-256c2.667-9.333 8.667-14 18-14 12 0 18 4.667 18 14l8 256-8 104m72 12c-9.333 0-14-4.667-14-14V308c0-6.667 4-11.333 12-14 24-9.333 52-14 84-14 58.667 0 109.667 19.333 153 58s67.667 86 73 142c16-6.667 32.667-10 50-10 34.667 0 64.667 12.333 90 37s38 53.667 38 87c0 34.667-12.667 64.333-38 89s-55.333 37-90 37l-358-2" />
        </svg>


      ),
    },

  ],
}




export default function Example() {
  return (
    <motion.div
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <motion.footer className="bg-nobel">
        <motion.div className="max-w-7xl mx-auto py-6 px-4 overflow-hidden sm:px-6 lg:px-8">
          <motion.nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
            {navigation.main.map((item) => (
              <motion.div key={item.name} className="px-5 py-2">
                <motion.a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                  {item.name}
                </motion.a>
              </motion.div>
            ))}
          </motion.nav>
          <motion.div className="mt-8 flex justify-center space-x-6">
            {navigation.social.map((item) => (
              <motion.a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                <motion.span className="sr-only">{item.name}</motion.span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </motion.a>
            ))}
          </motion.div>
        </motion.div>
        <motion.p className=" bg-silver_chalice text-center text-base text-gray-400">&copy; 2022 Rudie Crossman, Inc. All rights reserved.</motion.p>

      </motion.footer>
    </motion.div>
  )
}
