

import { useScroll } from "./useScroll";

import { motion } from "framer-motion";
import { scrollReveal } from "../animation";

export default function Example() {
    const [element, controls] = useScroll();

    return (
        <motion.div
            exit="exit"
            variants={scrollReveal}
            ref={element}
            animate={controls}
            initial="hidden" >
            <div className="relative px-4 py-16 sm:px-6 sm:py-20 lg:py-14 lg:px-8">

            </div>
        </motion.div>
    )
}
