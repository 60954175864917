import { motion } from "framer-motion";

import { useScroll } from "./useScroll";
import { scrollReveal } from "../animation";

export default function Example() {
    const [element, controls] = useScroll();

    return (
        <motion.div
            exit="exit"
            variants={scrollReveal}
            ref={element}
            animate={controls}
            initial="hidden"
            className="py-8 px-8 bg-silver_chalice bg-opacity-75">
            <motion.div class="aspect-w-16 aspect-h-9">
                <motion.iframe src="https://www.youtube.com/embed/lf91L3zvI6k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></motion.iframe>
            </motion.div>
        </motion.div>
    )
}
