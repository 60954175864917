import React from "react";
import { motion } from "framer-motion";

import NavBar from "../components/nav";

import Content from "../components/albumContent";
import Empty from "../components/Empty";
import Footer from "../components/footer"
import background1 from "../img/image13.jpeg";


const About = () => {
    return (
        <motion.div className=" bg-fixed bg-cover min-h-full max-h-full max-w-screen"
            style={{ backgroundImage: `url(${background1})` }}
        >
            <NavBar />
            <Empty />

            <Content />
            <Empty />

            <Footer />

        </motion.div>

    )
}
export default About;
