
import { motion } from "framer-motion";

import { useScroll } from "../components/useScroll";
import { scrollReveal } from "../animation";

import Spotify from "react-spotify-embed";

const EP = [
  {
    title: "Ik ga d'r uit",
    url: 'https://open.spotify.com/album/0KLhzU0aY80Li3VwmKI42b?si=mcvpVI_lQUOoeiSFDAYqGA',
    source:
      'https://www.top40.nl/media/cache/related/uploads/subtitle/14247/original.jpg',
  },
  {
    title: "Ik Weet...(Waar Ik Heen Wil Gaan)",
    url: 'https://open.spotify.com/album/0KLhzU0aY80Li3VwmKI42b?si=mcvpVI_lQUOoeiSFDAYqGA',
    source:
      'https://cdn.myonlinestore.eu/93cb2d1f-6be1-11e9-a722-44a8421b9960/image/cache/full/532bf3afab8dcc9ce43aa9c848e8f8b16197f12d.jpg',
  },
  {
    title: 'Echte Liefde',
    url: 'https://open.spotify.com/album/0KLhzU0aY80Li3VwmKI42b?si=mcvpVI_lQUOoeiSFDAYqGA',
    source:
      'https://i.discogs.com/xt5xwPYWZiZdm3IdMFLCwkapw0_GC4R_A_fwUFKxfI0/rs:fit/g:sm/q:90/h:596/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTU0Mzc1/NDctMTU1Nzk1ODAy/Ni00MzA5LmpwZWc.jpeg',
  },
  {
    title: 'Jij bent een kanjer',
    url: 'https://open.spotify.com/album/0KLhzU0aY80Li3VwmKI42b?si=mcvpVI_lQUOoeiSFDAYqGA',
    source:
      'https://f.eu1.jwwb.nl/public/6/a/4/discografie/crossman91jijbent.large.jpg',
  },

  // More files...
]

export default function Example() {
  const [element, controls] = useScroll();
  return (
    <motion.div
      variants={scrollReveal}
      ref={element}
      animate={controls}
      initial="hidden"
      className=" bg-silver_chalice bg-opacity-75">


      <motion.div
        exit="exit"
        variants={scrollReveal}
        ref={element}
        animate={controls}
        initial="hidden"
        className="max-w-7xl py-12 mx-auto sm:px-6 lg:px-8 ">
        <motion.h2 className="text-2xl font-bold leading-7 text-center text-gray-900 sm:text-3xl sm:truncate">ALBUMS & EP'S</motion.h2>
        <motion.div className="relative">
          <motion.div className=" pt-5 inset-0 flex justify-center" aria-hidden="true">
            <motion.div className="w-1/3  border-t border-gray-300" />
          </motion.div>
        </motion.div>
        <motion.h5 className="text-md  leading-7 py-2 pb-5 text-center text-gray-900 sm:text-md sm:truncate">
          <motion.p>
            Check out my latest <motion.a className="text-black underline" href="/albums">music</motion.a>. <motion.br />
            You can easily find them on <motion.a className="text-black underline" href="https://www.youtube.com/channel/UCRwJvIT5T7bubXsGD5kJSqA">Youtube</motion.a>, <motion.a className="underline text-black" href="https://music.apple.com/us/artist/rudie-crossman/1513900469">Itunes</motion.a> and <motion.a className="underline text-black" href="https://open.spotify.com/artist/3QC8RaxpLGVXMCHKbWAx1w?si=gb9zSc7fSwaEVueIsundOw">Spotify</motion.a>
          </motion.p>

        </motion.h5>


        <motion.ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {EP.map((file) => (

            <motion.li key={file} className="relative">

              <div className="group block w-full drop-shadow-2xl shadow-2xl aspect-w-10 aspect-h-10 rounded-lg overflow-hidden">
                <img src={file.source} alt="" className="object-cover pointer-events-none" />

                <div
                  className="absolute text-background left-1/2 -translate-x-1/2 w-full h-full flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 duration-300">
                  <Spotify className="w-full" link={file.url} />
                </div>
              </div>

            </motion.li>
          ))}

        </motion.ul>

        <motion.div className="text-center mt-3">
          <motion.a href="/albums" className="underline">
            See More
          </motion.a>

        </motion.div>

      </motion.div>
    </motion.div>
  )
}
