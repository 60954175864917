import img1 from "../img/image01.jpeg";


import { motion } from "framer-motion";


export default function Example() {
  return (
    <motion.div
      className=" bg-silver_chalice min-h-screen bg-opacity-75 overflow-hidden">
      <motion.div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <motion.div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <motion.div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <motion.div>
            <motion.h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Who is Rudie Crossman?
            </motion.h3>
          </motion.div>
        </motion.div>
        <motion.div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <motion.div className="relative lg:row-start-1 lg:col-start-2">
            <motion.svg
              className="hidden  lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <motion.defs>
                <motion.pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <motion.rect x={0} y={0} width={4} height={4} className="text-gray-100" fill="currentColor" />
                </motion.pattern>
              </motion.defs>
              <motion.rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </motion.svg>
            <motion.div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <motion.figure>
                <motion.div className="aspect-w-12 aspect-h-12 ">
                  <motion.img
                    className="rounded-lg shadow-lg object-cover object-center"
                    src={img1}
                  />
                </motion.div>
              </motion.figure>
            </motion.div>
          </motion.div>
          <motion.div className="mt-8 lg:mt-0">
            <motion.div className="text-base max-w-prose mx-auto lg:max-w-none">
              <motion.p className="text-lg text-gray-500">
                “And I thought about how many people have loved those songs. And how many people got through a lot of bad times because of those songs. I bet if I wrote one of them, I would be very proud. I hope the people who wrote those songs are happy. I hope they feel it's enough. I really do because they've made me happy.”
              </motion.p>
            </motion.div>
            <motion.div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <motion.p>
                Born in Amsterdam, Rudie is best known for his 70's, 80's, 90's & 00's music. He gained superstardom in 1990. Some claim that Rudie has changed the scope of music and paved the way for other famous artists.
              </motion.p>
              <motion.p>
                He caught the attention of audiences the world over with his perfect performance. Dutch singer and songwriter Rudie`s truthful songs and brilliant performance have amazed people all over the world. But with record sales and a growing popularity, the singer stays open to his fans.
              </motion.p>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}
