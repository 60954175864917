import React, { useState } from 'react';

import { motion } from "framer-motion";

import { useScroll } from "../components/useScroll";

import { scrollReveal } from "../animation";

import { useForm, ValidationError } from '@formspree/react';

import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {

  const [element, controls] = useScroll();
  const [state, handleSubmit] = useForm("mjvzndbr");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isMessageSent, setMessageSent] = useState(false);


  let confirmation = isMessageSent ?
    (<div className='text-indigo-900'>Message Sent!</div>) : null;

  function onChange() {
    setIsButtonDisabled(false);
    console.log(isButtonDisabled);
  }

  function sendMessage() {
    setMessageSent(true);
    console.log(isMessageSent);
  }

  return (
    <motion.div
      exit="exit"
      variants={scrollReveal}
      ref={element}
      animate={controls}
      initial="hidden"
      className="overflow-hidden py-6 sm:px-6 lg:px-8 bg-silver_chalice bg-opacity-75 lg:py-12">


      <motion.div className="relative max-w-xl mx-auto">

        <motion.div className="text-center">
          <motion.h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Contact</motion.h2>
          <motion.p className="mt-4 text-lg leading-6 text-gray-500">
            Feel free to contact us for more information about the upcoming events and other queries.
          </motion.p>
          <motion.div className="relative">
            <motion.div className=" pt-5 inset-0 flex justify-center" aria-hidden="true">
              <motion.div className="w-1/2  border-t border-gray-300" />
            </motion.div>
          </motion.div>

        </motion.div>
        <motion.div className="mt-12">
          <motion.form onSubmit={handleSubmit} method="POST" className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <motion.div>
              <motion.label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                First name
              </motion.label>
              <motion.div className="mt-1">
                <motion.input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
                <ValidationError
                  prefix="First-Name"
                  field="firstname"
                  errors={state.errors}
                />
              </motion.div>
            </motion.div>
            <motion.div>
              <motion.label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                Last name
              </motion.label>
              <motion.div className="mt-1">
                <motion.input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
                <ValidationError
                  prefix="Last-Name"
                  field="last-name"
                  errors={state.errors}
                />
              </motion.div>
            </motion.div>

            <motion.div className="sm:col-span-2">
              <motion.label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </motion.label>
              <motion.div className="mt-1">
                <motion.input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </motion.div>
            </motion.div>
            <motion.div className="sm:col-span-2">
              <motion.label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Message
              </motion.label>
              <motion.div className="mt-1">
                <motion.textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                  defaultValue={''}
                />
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </motion.div>
            </motion.div>

            <div className="sm:col-span-2 sm:flex sm:justify-end">
              <ReCAPTCHA
                className='max-w-screen'
                required
                size='compact'
                sitekey="6LcRL3QjAAAAAMjEAKaEGXZw4crxFG-jYzAgmfXm"
                onChange={onChange}
              />
            </div>

            <motion.div className="sm:col-span-2">

              {confirmation}
              <motion.button
                id='formSubmit' disabled={isButtonDisabled ? true : false} onClick={sendMessage}
                type="submit"
                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-boulder hover:bg-dove_gray"
              >
                Let's talk
              </motion.button>
            </motion.div>
          </motion.form>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export default Contact;
