//gallery
import image1 from "../img/image01.jpeg";
import image2 from "../img/image02.jpeg";
import image3 from "../img/image03.jpeg";
import image4 from "../img/image04.jpeg";
import image5 from "../img/image05.jpeg";
import image6 from "../img/image06.jpeg";
import image7 from "../img/image07.jpeg";
import image8 from "../img/image08.jpeg";
import image9 from "../img/image09.jpeg";
import image10 from "../img/image10.jpeg";
import image11 from "../img/image11.jpeg";
import image12 from "../img/image12.jpeg";
import image13 from "../img/image13.jpeg";
import image14 from "../img/image14.jpeg";
import image15 from "../img/image15.jpeg";
import image16 from "../img/image16.jpeg";
import image17 from "../img/image17.jpeg";


import { motion } from "framer-motion";

import ModalImage from "react-modal-image-react-17";

const images = [
  { source: image1, isOpen: false },
  { source: image4, isOpen: false },
  { source: image5, isOpen: false },

  { source: image7, isOpen: false },
  { source: image8, isOpen: false },
  { source: image12, isOpen: false },

  { source: image9, isOpen: false },
  { source: image13, isOpen: false },
  { source: image14, isOpen: false },

  { source: image17, isOpen: false },
  { source: image15, isOpen: false },
  { source: image6, isOpen: false },

  { source: image10, isOpen: false },
  { source: image11, isOpen: false },
  { source: image16, isOpen: false },

  { source: image2, isOpen: false },
  { source: image3, isOpen: false },

];



export default function Example() {
  return (
    <motion.div
      className=" bg-silver_chalice min-h-screen bg-opacity-75 overflow-hidden">

      <section class="overflow-hidden min-h-screen text-gray-700 ">
        <div class="container px-5  mx-auto py-10 lg:px-32">
          <div class="flex flex-wrap -m-1 md:-m-2">
            {images.map((item) => (
              <div class="flex flex-wrap w-1/3">
                <div class="w-full p-1 md:p-2">
                  <ModalImage
                    className="block object-cover object-center w-full h-full rounded-lg"
                    small={item.source}
                    large={item.source}
                    hideDownload
                  />

                </div>
              </div>
            ))}

          </div>
        </div>
      </section>

    </motion.div>
  )
}