import React from "react";

//Import Pages
import Home from "./pages/home";
import About from "./pages/about";
import Album from "./pages/album";
import Gallery from "./pages/gallery";
import Contact from "./pages/contact";

// Import Components 


//Router
import { Routes, Route, BrowserRouter } from "react-router-dom";
//Animation
import { AnimatePresence } from "framer-motion";



import "./style/scroll.css";


function App() {
  return (
    <div className=" App">
      <BrowserRouter>
        <AnimatePresence exitBeforeEnter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/albums" element={<Album />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />

          </Routes >
        </AnimatePresence>
      </BrowserRouter>
    </div>
  );
}

export default App;
