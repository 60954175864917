import { motion } from "framer-motion";
import img1 from "../img/image17.jpeg";
import img2 from "../img/image12.jpeg";
import img3 from "../img/image13.jpeg";
import Spotify from "react-spotify-embed";

const EP = [
  {
    title: "Ik ga d'r uit",
    url: 'https://open.spotify.com/album/0KLhzU0aY80Li3VwmKI42b?si=mcvpVI_lQUOoeiSFDAYqGA',
    source:
      'https://www.top40.nl/media/cache/related/uploads/subtitle/14247/original.jpg',
  },
  {
    title: "Rudie Crossman",
    url: 'https://open.spotify.com/album/0CGKH73foHfBvW944mueTQ',
    source: img1,
  },
  {
    title: 'Rudie',
    url: 'https://open.spotify.com/album/6vjIrAl8PlpIn3xfp1Een2',
    source: img2,
  },
  {
    title: 'I Need You',
    url: 'https://open.spotify.com/album/37JOEhXTG17WcqccUyFyeB',
    source: img1,
  },
  {
    title: 'Echte Liefde',
    url: 'https://open.spotify.com/album/2Y5xQTWvECAK3LuTV0hSqk',
    source: img3,
  },
  {
    title: 'De Laatste Kus',
    url: 'https://open.spotify.com/album/0qPNeSCn4P6QW6PSCYXPEk',
    source: img3,
  },

  // More files...
]


export default function Example() {
  return (
    <motion.div
      className=" bg-silver_chalice min-h-screen bg-opacity-75 overflow-hidden">

      <motion.div
        className="max-w-7xl py-12 mx-auto sm:px-6 lg:px-8 ">
        <motion.h2 className="text-2xl font-bold leading-7 text-center text-gray-900 sm:text-3xl sm:truncate">ALBUMS & EP'S</motion.h2>
        <motion.div className="relative">
          <motion.div className=" pt-5 inset-0 flex justify-center" aria-hidden="true">
          </motion.div>
        </motion.div>


        <motion.ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {EP.map((file) => (

            <motion.li key={file} className="relative">

              <div className="group block w-full drop-shadow-2xl shadow-2xl aspect-w-10 aspect-h-10 rounded-lg overflow-hidden">
                <img src={file.source} alt="" className="object-cover pointer-events-none" />

                <div
                  className=" opacity-0  group-hover:opacity-100 duration-300">
                  <Spotify className="w-full " link={file.url} />
                </div>
              </div>

            </motion.li>
          ))}

        </motion.ul>

      </motion.div>

    </motion.div>
  )
}
