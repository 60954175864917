import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom'

import { motion } from "framer-motion";

const navigation = [
  { name: 'Home', href: '/', id: 'HomeBox' },
  { name: 'About', href: '/about', id: 'aboutBox' },
  { name: 'Albums', href: '/albums', id: 'albumBox' },
  { name: 'Gallery', href: '/gallery', id: 'galleryBox' },
  { name: 'Contact', href: '/contact', id: 'ContactBox' },

]

export default function Example() {
  return (
    <div className=' bg-silver_chalice bg-opacity-75 top-0 z-50'>
      <div className="">
        <div className="relative py-4  ">
          <Popover>
            <div className="max-w-7xl max-h-screen mx-auto px-4 sm:px-6 ">
              <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
                <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className=" rounded-md p-2 inline-flex items-center justify-center hover:text-gray-500 focus:outline-none">
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>

                  </div>

                </div>
                <div className="hidden md:flex  md:space-x-10 ">
                  {navigation.map((item) => (
                    <NavLink key={item.name} to={item.href} className={({ isActive }) =>
                      isActive ? "font-medium text-black hover:text-white" : "font-medium text-white hover:text-silver"
                    }>
                      {item.name}
                    </NavLink>
                  ))}

                </div>

              </nav>

            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-10 top-0 inset-x-0 p-2 bg-black transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md  ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex  items-center justify-between">
                    <div className="-mr-2">
                      <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500  focus:outline-none">
                        <span className="sr-only">Close main menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3  text-center space-y-1">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block px-3 py-2  rounded-md text-base font-medium text-background text-white z-50"
                      >
                        {item.name}
                      </a>

                    ))}
                  </div>

                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

        </div>
      </div>
    </div>
  )
}

