import React from "react";
//Page Components

import Banner from "../components/banner";
import Carousel from "../components/carousel";
import Contact from "../components/contact";
import VideoPlayer from "../components/video";
import Empty from "../components/Empty";
import Footer from "../components/footer"

import background1 from "../img/image13.jpeg";

//Animations
import ScrollTop from "../components/Scrolltop";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <motion.div className="  bg-fixed bg-cover bg-center min-h-full max-h-full max-w-screen"
      style={{ backgroundImage: `url(${background1})`, backgroundRepeat: 'no-repeat' }}
    >
      <div className="bg-silver_chalice bg-opacity-40 overflow-hidden">


        <Banner />

        <Carousel />
        <Empty />
        <VideoPlayer />
        <Empty />
        <Contact />
        <Empty />

        <ScrollTop />
      </div>
      <Footer />

    </motion.div>
  );
};

export default Home;